<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Branch')} ${$t('To Edit')}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <!-- Field: Logo -->
            <b-col
              md="12"
            >
              <!-- Media -->
              <b-media class="mb-2">
                <template #aside>
                  <b-avatar
                    ref="previewEl"
                    :src="branchData.logo"
                    size="90px"
                    rounded
                  />
                </template>
                <h4 class="mb-1">
                  {{ branchData.name }}
                </h4>
                <div class="d-flex flex-wrap">
                  <b-button
                    variant="outline-primary"
                    @click="$refs.refInputEl.click()"
                  >
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="inputImageRenderer"
                    >
                    <feather-icon icon="FolderIcon" />&nbsp;
                    <span class="d-none d-sm-inline">Browse</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </div>
              </b-media>

            </b-col>

            <!-- Field: Branch Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="branchData.name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Phone -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Phone"
                rules="required|integer"
              >
                <b-form-group
                  :label="$t('Phone')"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="branchData.phone"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Address -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="address"
                rules="required"
              >
                <b-form-group
                  :label="$t('Address')"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="branchData.address"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-col>

            <!-- Field: Description -->
            <b-col
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="description"
                rules="required"
              >
                <b-form-group
                  :label="$t('Description')"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="branchData.description"
                    :state="getValidationState(validationContext)"
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex justify-content-between mt-2">
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                class="mr-1"
                pill
                type="submit"
              >
                {{ $t('Save') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="gradient-secondary"
                pill
                @click="cancel"
              >
                {{ $t('Cancel') }}
              </b-button>
            </div>

            <div>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="flex-2"
                variant="flat-primary"
                pill
                @click="addEmployee"
              >
                {{ $t('Employee') + ' ' + $t('Add') }}
              </b-button>
            </div>

          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton,
  BMedia, BAvatar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import branchStoreModule from '../branchStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  props: {
  },
  data() {
    return {
      required,
    }
  },
  computed: {
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'branch-list' })
    },
    addEmployee() {
      this.$router.push({ name: 'employee-new', query: { branch_id: this.$router.currentRoute.params.id } })
    },
  },
  setup(props, context) {
    const STORE_MODULE_NAME = 'branch'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, branchStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const blankData = {
      name: '',
      description: '',
      phone: '',
      address: '',
      logo: '',
    }

    const router = context.root.$router
    const branchData = ref(JSON.parse(JSON.stringify(blankData)))
    const objectId = router.currentRoute.params.id
    store.dispatch('branch/fetchOne', { id: objectId })
      .then(response => {
        const responseBranchData = {
          id: response.data.data.id,
          name: response.data.data.name,
          description: response.data.data.description,
          phone: response.data.data.phone,
          address: response.data.data.address,
          logo: response.data.data.logo,
        }
        branchData.value = responseBranchData
      })
      .catch(error => {
        if (error.response.status === 404) {
          branchData.value = undefined
        }
        window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
      })

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      branchData.value.logo = base64
    })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(branchData.value))
      store.dispatch('branch/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'branch-list' })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      branchData,
      onSubmit,

      refFormObserver,
      getValidationState,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
